<style>
	.center {
		width: 100vw;
		height: 100vh;
		background-image: url('/src/assets/b10.png');
		//background-size: 100% 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	myh1 {
		font-size: 50px;
		color: white;
		/* margin-top: -120px; */
	}

	myh3 {
		font-size: 50px;
		color: white;
		/* margin-top: -80px; */
	}

	.logon {
		/* background-color: #fff; */
		background-color: rgba(255, 255, 255, 0.2);
		border-radius: 10px;
		/* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
		/* position: relative;
		overflow: hidden; */
		width: 400px;
		max-width: 100%;
		min-height: 480px;
		margin-top: -40px;
		margin-left: 700px;
		display: flex;
		/* background: -webkit-linear-gradient(right, #4284db, #29eac4); */
		/* background-image: radial-gradient(circle at 48.7% 44.3%, rgb(30, 144, 231) 0%, rgb(56, 113, 209) 22.9%, rgb(38, 76, 140) 76.7%, rgb(31, 63, 116) 100.2%); */

	}

	.overlaylong {
		border-radius: 10px 10px 10px 10px;
		width: 100%;
		height: 100%;
		/* background-color: #fff; */
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.overlaylongleft {
		border-radius: 0px 10px 10px 0px;
		width: 50%;
		height: 100%;
		background-color: #fff;
		transform: translateX(100%);
		transition: transform 0.6s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.overlaylongright {
		border-radius: 10px 0 0 10px;
		width: 50%;
		height: 100%;
		background-color: #fff;
		transform: translateX(0%);
		transition: transform 0.6s ease-in-out;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.overlaytitle {
		border-radius: 0px 10px 10px 0px;
		width: 50%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		display: flex;
		align-items: center;
		justify-content: center;
	}


	.overlaytitleH2 {
		font-size: 30px;
		color: #fff;
		margin-top: 20px;
	}

	.overlaytitleP {
		font-size: 20px;
		color: #fff;
		margin-top: 20px;
	}

	.overlaytitleleft {
		border-radius: 0px 10px 10px 0px;
		width: 50%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(0%);
		transition: transform 0.6s ease-in-out;
	}

	.overlaytitleright {
		border-radius: 0px 10px 10px 0px;
		width: 50%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(-100%);
		transition: transform 0.6s ease-in-out;
	}

	.overlaytitle-Signin {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.overlaytitle-Signup {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.buttongohs {
		width: 180px;
		height: 40px;
		border-radius: 50px;
		border: 1px solid #fff;
		color: #fff;
		font-size: 15px;
		text-align: center;
		line-height: 40px;
		margin-top: 40px;
	}

	.overlaylongH2 {
		font-size: 30px;
		color: white;
		/* width: 250px; */
	}

	.overlaylong-Signin {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.overlaylong-Signup {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 10px 0;
		width: 240px;
	}

	.myh3 {
		font-size: 10px;
		margin-top: 10px;
		cursor: pointer;
	}

	.inupbutton {
		background-color: #405fea;
		border: none;
		width: 120px;
		height: 40px;
		border-radius: 50px;
		font-size: 20px;
		font-weight: 600;
		color: #fff;
		text-align: center;
		line-height: 40px;
		margin-top: 30px;
	}

</style>

<template>
	<div class="center">
		<!-- <h1>Sign in/ Sign up</h1> -->
<!-- 		<div
			style="font-size: 55px; font-weight:bold;color: white;margin-top: -100px;margin-bottom: 150px;text-shadow: 3px 3px 3px #00f;">
			盐城市大丰区企业用工监测和劳动力动态管理系统
		</div> -->
		<div class="logon">
			<div :class="overlaylong">
				<div class="overlaylong-Signin">
					<h2 class="overlaylongH2">欢 迎 登 陆</h2>

					<el-form class="overlaylong-Signin" :model="loginForm" :rules="loginFormRules">
						<el-input prefix-icon="el-icon-user" type="text" placeholder="企业名称/手机号/机构代码/登陆账号"
							v-model="loginForm.username" style="width: 280px;font-size: 18px;"></el-input>
						<el-input type="password" prefix-icon="el-icon-lock" placeholder="密码"
							v-model="loginForm.password" style="width: 280px;font-size: 18px;"></el-input>
						<div style="display:flex; vertical-align: bottom;">
							<div>
								<el-input type="text" auto-complete="false"
									style="width: 156px; margin-right: 5px;font-size: 18px;" v-model="loginForm.code"
									placeholder="点击图片更换验证码"></el-input>
							</div>

							<div class="login-code" @click="refreshCode" style="margin-top: 10px;">
								<!--验证码组件-->
								<s-identify :identifyCode="identifyCode"></s-identify>
							</div>
						</div>

					</el-form>


					<!-- <h3>Forgot your password?</h3> -->
					<div style="display:flex; vertical-align: bottom;">
	<!-- 					<button class="inupbutton" @click="login()">登 陆</button>
						<div style="margin-left: 10px;">
							<button class="inupbutton" @click="zhuche()">注 册</button>
						</div> -->
						<el-button  type="primary" style="margin-top: 30px; width:120px;"  @click="login()">登 陆</el-button>
						<div style="margin-left: 10px;">
							<el-button  type="primary" style="margin-top: 30px;width:120px" @click="zhuche()">注 册</el-button>
						</div>

					</div>
				</div>

			</div>
			<!-- 			<div :class="overlaytitle">
				<div class="overlaytitle-Signin">
					<h2 class="overlaytitleH2">盐城市大丰区</h2>
					<p class="overlaytitleP">
						企业用工监测和劳动力动态管理系统
					</p>
				</div>
			</div> -->
		</div>

	</div>
</template>


<script>
	import {
		isNull,
		treeDataTranslate
	} from "../assets/js/FormatTree.js";
	import {
		setRoutes
	} from "@/router";
	import SIdentify from '../components/sldentify';
	export default {
		name: 'login',
		components: {
			SIdentify
		},
		data() {
			const validateCode = (rule, value, callback) => {
				if (this.identifyCode !== value) {
					this.loginForm.code = ''
					this.refreshCode()
					callback(new Error('请输入正确的验证码'))
				} else {
					callback()
				}
			}
			return {
				overlaylong: 'overlaylong',
				overlaytitle: 'overlaytitle',
				disfiex: 0,
				loginForm: {
					username: '',
					password: '',
					code: ''
				},
				identifyCodes: '1234567890',
				identifyCode: '',
				checked: true,
				menuid: [],
				loginFormRules: {
					/* 验证用户名是否合法 */
					username: [{
							required: true,
							message: '请输入企业名称/手机号/机构代码/登陆账号',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 100,
							message: '长度在 3 到 100 个字符',
							trigger: 'blur'
						}
					],
					/* 验证密码 */
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 15,
							message: '长度在 6 到 15 个字符',
							trigger: 'blur'
						}
					]
				},

			}
		},
		watch: {
			identifyCode(v) {
				this.isDebugLogin && (this.loginForm.code = v)
			}
		},
		methods: {
			resetLoginForm() {
				// console.log(this)
				this.$refs.loginFormRef.resetFields()
			},
			openvue() {
				this.$router.push({
					name: "home",
				});
			},
			zhuche() {
				this.$router.push({
					name: "Register",
				});
			},
			login() {
				var that = this;
				// console.log(this.loginForm)
				if (this.loginForm.code !== this.identifyCode) {
					this.$message.error("验证码不正确！");
					return;
				};
				this.$axios.post("https://qyyg.qiaokuaiji.com/wt_login.fsp?proc=login", this.loginForm)
					.then(function(res) { //处理成功	
						//console.log(res)
						// console.log(res.data)
						if (res.data.errno != 0) { //如果出错则显示后端错误
							that.$message.error(res.data.errmsg);
							return
						}
						window.sessionStorage.setItem('username', res.data.username)
						window.sessionStorage.setItem('token', res.data.token)
						window.sessionStorage.setItem('avatar_url', res.data.avatar_url)
						window.sessionStorage.setItem('nickname', res.data.nickname)
						window.sessionStorage.setItem('rolename', res.data.rolename)

						//that.menuid=res.data.table.rows
						that.menuid = treeDataTranslate(res.data.table.rows, 'id_prikey', 'pid');
						// console.log(that.menuid)
						window.sessionStorage.setItem('sysmenu', JSON.stringify(that.menuid))
						//动态设置当前用户的路由
						setRoutes()
						that.$message.success("登陆成功")
						// console.log(res.data.rolename)
						if (res.data.rolename === "leadinguser") {
							that.$router.push("/front/home")
						} else {
							// that.$router.push("/")
							that.openvue()
						}

					})
					.catch(function(error) { //处理失败
						// console.log(error)
					});
			},
			randomNum(min, max) {
				return Math.floor(Math.random() * (max - min) + min)
			},
			refreshCode() {
				this.identifyCode = ''
				this.makeCode(this.identifyCodes, 4)
			},
			makeCode(o, l) {
				for (let i = 0; i < l; i++) {
					this.identifyCode += this.identifyCodes[
						this.randomNum(0, this.identifyCodes.length)
					]
				}
				//console.log(this.identifyCode);
			},
		},
		created() {
			this.refreshCode()
		}
	}
</script>